/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import layout from './assets/footerlayout.svg'
import desktopLayout from './assets/footerDesktop.svg'
import GatsbyLink from 'components/utils/GatsbyLink'
import { Context } from 'components/Layout'

interface Props {
  children?: React.ReactNode
  location?: Location
}
// const FooterLegalDark: React.FC = (): React.ReactElement => {
//   return <img src={footerLegalDark} />
// }

// const FooterLegalLight: React.FC = (): React.ReactElement => {
//   return <div />
// }

// const FooterLogoDark: React.FC = (): React.ReactElement => {
//   return <img src={nanostringLogoDark} />
// }

// const FooterLogoLight: React.FC = (): React.ReactElement => {
//   return <div />
// }

const Footer: React.FC<Props> = ({ location }): React.ReactElement => {
  const { pathname } = location
  const height = pathname === '/' ? 0 : '19rem'
  const pages: { path: string; label: string }[] = [
    { path: '/profile', label: 'Build Your Profile' },
    { path: '/explore', label: 'Explore Data Applications' },
    { path: '/analyze', label: 'Analyze Your Data' },
    { path: 'demo', label: 'Schedule a Consultation' },
  ]
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            height: context.isLandscape ? '10rem' : height,
            backgroundColor: '#fff',
            width: '100%',
          }}
          animate={{ height: context.isLandscape ? '10rem' : height }}
        >
          <div sx={{ width: '100%', height: '100%', position: 'relative' }}>
            {location.pathname !== '/' && (
              <nav
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderBottom: 'solid 1px #BFC4C8',
                }}
              >
                <ul
                  sx={{
                    margin: 0,
                    padding: 0,
                    listStyle: 'none',
                    display: 'flex',
                    width: context.isLandscape ? '70%' : '100%',
                    flexDirection: 'row',
                    height: context.isLandscape ? '4rem' : '4rem',
                  }}
                >
                  {pages.map((page, i: number) => {
                    return (
                      <li
                        key={`link-${i}`}
                        sx={{
                          margin: 0,
                          padding: 0,
                          fontSize: context.isLandscape ? '1rem' : '1rem',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          lineHeight: '1',
                          width: '25%',
                        }}
                      >
                        <GatsbyLink
                          to={page.path}
                          sx={{
                            px: '20%',
                            fontWeight: '500',
                            fontSize: ['.7rem', '.9rem', '1.1rem'],
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'primary',
                            textDecoration: 'none',
                            backgroundColor: '#fff',
                            transition: 'all .3s ease',
                            '&.active,&:hover': {
                              color: '#fff',
                              backgroundColor: 'primary',
                            },
                            justifyContent: 'center',
                          }}
                          activeClassName="active"
                          className={
                            pathname.indexOf(page.path) !== -1 ? 'active' : ''
                          }
                        >
                          {page.label}
                        </GatsbyLink>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            )}
            <motion.img
              src={context.isLandscape ? desktopLayout : layout}
              sx={{
                opacity: 0,
                width: '100%',
                bottom: 0,
                position: 'absolute',
              }}
              animate={{ opacity: 1 }}
            />
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export default Footer

/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import layout from './assets/headerlayout.svg'
import desktopLayout from './assets/headerDesktop.svg'
import GatsbyLink from 'components/utils/GatsbyLink'
import { Context } from 'components/Layout'

interface Props {
  children?: React.ReactNode
  location?: Location
}

const Header: React.FC<Props> = ({ location }): React.ReactElement => {
  const { pathname } = location
  const height = pathname === '/' ? 0 : '13rem'
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            width: '100%',
            display: 'flex',
            backgroundColor: '#fff',
            flexDirection: ['column', null, null, 'row'],
          }}
          initial={{ height: '0%' }}
          transition={{ duration: 0.5 }}
          animate={{ height: '100%' }}
          exit={{ height: '0%' }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            exit={{ opacity: 0 }}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              top: 0,
            }}
          >
            <GatsbyLink to="/" sx={{ width: '100%', height: '100%' }}>
              <img
                src={context.isLandscape ? desktopLayout : layout}
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  top: 0,
                }}
                // animate={{ opacity: 1 }}
              />
            </GatsbyLink>
          </motion.div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export default Header
